import { Outlet } from "react-router-dom";

import { LogoIcon } from "@/assets/icons";
import authImage from "@/assets/images/entranceImage.jpeg";

import styles from "./Auth.module.scss";

const Auth = () => {
  return (
    <div className={styles.auth}>
      <div className={styles.inner}>
        <div className={styles.col}>
          <div className={styles.logo}>
            <LogoIcon />
          </div>
          <Outlet />
          <p className={styles.footer}>© 2023. Все права защищены</p>
        </div>
      </div>
      <div className={styles.img}>
        <img src={authImage} alt="auth" />
      </div>
    </div>
  );
};

export default Auth;
